<template>
  <div class="bg-gray-900 pt-10 article-head-padding">
    <div class="container pb-10 md:pb-15">
      <div class="grid-container md:px-11">
        <div
          v-if="article.videoProvider && article.videoId"
          class="list-title-m col-span-6 sm:col-span-10 aspect-video"
        >
          <!-- Set title to space to avoid grabbing it from Vimeo -->
          <media-player
            title="&nbsp;"
            :src="`${article.videoProvider}/${article.videoId}`"
          >
            <media-provider></media-provider>
            <media-video-layout></media-video-layout>
          </media-player>
        </div>
        <div
          v-else-if="article.toppictHTML"
          class="list-title-m col-span-12 sm:col-span-12"
          v-html="article.toppictHTML"
        />
        <div class="col-span-6 sm:col-span-10">
          <div class="text-white opacity-50 lowercase article-label mt-6">
            {{ publishingDateString }}

            <span
              v-if="section"
              :style="{ color: section.color }"
              :class="{ 'text-blue': !isMm() }"
              class="ml-2 font-bold"
              ><NuxtLink :to="'/' + section.url">{{
                section.title
              }}</NuxtLink></span
            >
          </div>
          <h1
            class="headline-xs mb-5 md:mb-4 mt-4 text-white"
            v-html="article.headline"
          />
          <div class="body text-white" v-html="article.mainTeaser" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ArticleDTO } from '~/typesAuto/apicore/v1'
import type { ContentPaper } from '~/typesManual/content_api/paper'

import 'vidstack/bundle'

const route = useRoute()

const indexStore = useIndexStore()

const props = defineProps<{
  article: ArticleDTO
  primaryPaper?: ContentPaper
}>()

const publishingDateString = computed(() => {
  const paper = props.article.papers?.find(
    (paper) => paper.paperId === indexStore.currentPaper?.RecordId
  )

  if (paper?.publishingDate) {
    return formatDateTime(new Date(paper.publishingDate))
  }

  return ''
})

const section = computed(() => {
  switch (route.name) {
    case 'article':
    case 'article_index':
    case 'names_index':
    case 'article_paper':
    case 'names_paper':
    case 'article_debate':
      if (isMm()) {
        if (props.primaryPaper && props.primaryPaper.RecordId !== 1) {
          return {
            url: props.primaryPaper.Url,
            title: props.primaryPaper.Name,
            color: props.primaryPaper.ColorRGB,
          }
        }
      }
      if (indexStore.currentPaper && indexStore.currentPaper.RecordId !== 1) {
        return {
          url: indexStore.currentPaper.Url,
          title: indexStore.currentPaper.Name,
        }
      }
    default:
      return
  }
})
</script>
