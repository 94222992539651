<template>
  <div
    class="col-span-6 sm:col-span-12 flex items-center md:items-start flex-col md:flex-row pb-4 mt-8 md:mt-0"
  >
    <img
      :src="config.public.site.legacydomain + podcast.image"
      class="rounded-lg w-40 h-40 md:w-24 md:h-24 shrink-0"
    />
    <div
      class="flex flex-col justify-between ml-0 md:ml-3 items-center md:items-start"
    >
      <CommonButton
        size="xlarge"
        border="border-none"
        text="text-white"
        text-hover="text-white"
        class="group mt-5 md:mt-0"
        :style="{ backgroundColor: podcast.color }"
        @click="playAudio"
      >
        <img
          src="@/assets/icons/play.svg?component?url"
          class="w-4 h-4 inline-block mr-2 fill-current group-hover:text-white transition-colors duration-100"
        />
        {{ $t('PlayEpisode') }}
      </CommonButton>
      <CommonButton
        size="xlarge"
        bg="bg-white"
        bg-hover="bg-white"
        border="border-none"
        class="mt-10 mb-3 md:mb-0 md:mt-3"
      >
        Find {{ podcast.name }} i&nbsp;<a
          v-if="podcast.acast"
          :href="podcast.acast"
          class="underline"
          target="_blank"
          >Acast</a
        >&nbsp;|&nbsp;<a
          v-if="podcast.itunes"
          :href="podcast.itunes"
          class="underline"
          target="_blank"
          >iTunes</a
        >&nbsp;|&nbsp;<a
          v-if="podcast.spotify"
          :href="podcast.spotify"
          class="underline"
          target="_blank"
          >Spotify</a
        >
      </CommonButton>
    </div>
    <CommonDivider class="md:hidden -mb-8" />
  </div>
</template>

<script setup lang="ts">
import type { Podcast } from '~/typesAuto/apicore/v1'

const config = useRuntimeConfig()
const dataLayer = useDataLayer()
const props = defineProps<{ podcast: Podcast; title: string }>()
const audioPlayer = useAudioPlayer()
const playAudio = () => {
  // Track plays
  dataLayer.podcast.play(props.podcast.recordid ?? '', props.title)

  audioPlayer.play({
    url: config.public.site.legacydomain + props.podcast.file,
    imageUrl: config.public.site.legacydomain + props.podcast.image,
    title: props.title,
  })
}
</script>
