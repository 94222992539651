<template>
  <div>
    <CommonError
      v-if="showNotFound"
      :title="$t('errorPage.articleNotFound.title')"
      :description="$t('errorPage.articleNotFound.description')"
    >
      <CommonDivider />
    </CommonError>

    <Article :id="articleId" />
    <LayoutStaticPageBottom />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()

const showNotFound = ref(false)
const articleId = ref('')

if (typeof route.params.id === 'string') {
  articleId.value = route.params.id
} else {
  articleId.value = route.params.id[0]
}

onErrorCaptured((event) => {
  const statusCode = (event.cause as Error & { statusCode: number }).statusCode

  if (statusCode === 404) {
    showNotFound.value = true
    return false
  }
})
</script>
