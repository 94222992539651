import { createApp, h } from 'vue'
import { AdsConceptAd } from '#components'

const AD_ID = 'altinget-in-text'
const AD_ID_MOB = 'altinget-in-text-mob'
const INCREMENT_VALUE = 14
const INITIAL_PARAGRAPH_TARGET_COUNT = 5
export default function injectAds(
  contentList: (HTMLParagraphElement | HTMLQuoteElement)[]
) {
  // only run client side
  if (import.meta.server) return

  let paragraphTargetCount = INITIAL_PARAGRAPH_TARGET_COUNT

  // return if there are not enough <p> elements
  if (contentList.length < paragraphTargetCount) return

  // calculate the number of ads to inject
  const adIterationCount = Math.round(contentList.length / INCREMENT_VALUE)

  for (let i = 1; i <= adIterationCount; i++) {
    const array = contentList.slice(
      paragraphTargetCount - INITIAL_PARAGRAPH_TARGET_COUNT,
      paragraphTargetCount
    )
    injectAd(i, array)

    paragraphTargetCount += INCREMENT_VALUE
  }
}

const injectAd = (
  index: number,
  slicedContentList: (HTMLParagraphElement | HTMLQuoteElement)[]
) => {
  // return if the ad is already injected
  if (document.getElementById(`article-ad-${index}`)) return

  let elementTarget = slicedContentList[4]

  if (!elementTarget) return

  let isBlockquote = false

  // if there is a blockquote element in the array, set it as the target element
  slicedContentList.forEach((element) => {
    if (element.tagName === 'BLOCKQUOTE') {
      elementTarget = element
      isBlockquote = true
    }
  })

  // create div element to hold the ad
  const adContainer = document.createElement('div')
  adContainer.id = `article-ad-${index}`
  adContainer.className = `mb-4`

  const parent = elementTarget.parentNode

  if (!parent) return

  // insert the adContainer before elemntTarget or elementTarget's next sibling if not a blockquote
  parent.insertBefore(
    adContainer,
    isBlockquote ? elementTarget : elementTarget.nextSibling
  )

  // create a new Vue instance and mount the ConceptAd component to the adContainer
  const adApp = createApp({
    render: () => [
      h(AdsConceptAd, {
        id: AD_ID + index,
        tempFullWith: true,
        class: 'hidden md:block',
      }),
      h(AdsConceptAd, {
        id: AD_ID_MOB + index,
        tempFullWith: true,
        class: 'block md:hidden',
      }),
    ],
  })

  adApp.mount(adContainer)
}
